module.exports = [{
      plugin: require('../node_modules/@whitespace/gatsby-plugin-cookie-consent/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@whitespace/gatsby-plugin-page-wrapper/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@whitespace/gatsby-plugin-i18next/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"sv","languages":["sv"]},
    },{
      plugin: require('../node_modules/@whitespace/gatsby-theme-wordpress-basic/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/var/lib/jenkins/workspace/stage.skanevux.se","fragmentsDir":"/var/lib/jenkins/workspace/stage.skanevux.se/src/fragments","i18next":{"defaultLanguage":"sv","languages":["sv"]},"wp":{"url":"https://cms.skanevux.se/graphql","contentTypes":{"page":{},"educationnv":{},"educationno":{},"educationsv":{},"educationso":{}},"nodesPerFetch":250},"postCss":{"postcssOptions":{"plugins":[null]}}},
    },{
      plugin: require('../node_modules/@municipio/gatsby-theme-basic/gatsby-browser.js'),
      options: {"plugins":[],"basePath":"/var/lib/jenkins/workspace/stage.skanevux.se","fragmentsDir":"/var/lib/jenkins/workspace/stage.skanevux.se/src/fragments","i18next":{"defaultLanguage":"sv","languages":["sv"]},"wp":{"url":"https://cms.skanevux.se/graphql","contentTypes":{"page":{},"educationnv":{},"educationno":{},"educationsv":{},"educationso":{}},"nodesPerFetch":250},"postCss":{"postcssOptions":{"plugins":[null]}}},
    }]
