import { H, Section } from "@jfrk/react-heading-levels";
import { Button, Link, Icon } from "@whitespace/components";
import {
  SearchTeaser,
  SearchTeaserContent,
  SearchTeaserMeta,
  SearchTeaserTitle,
} from "@whitespace/gatsby-plugin-search/src/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Occasions from "../../../components/Occasions";
import useApproval from "../../../hooks/useApproval";
import useCornersInfo from "../../../hooks/useCornersInfo";
import useEducationInfo from "../../../hooks/useEducationInfo";
import useEducationSettings from "../../../hooks/useEducationSettings";
import useRequirements from "../../../hooks/useRequirements";

import * as defaultStyles from "./SearchHit.module.css";

SearchHit.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
  label: PropTypes.string,
  secondaryTitle: PropTypes.string,
  url: PropTypes.string,
  corner: PropTypes.string,
  educationInformation: PropTypes.object,
  educationOtherContent: PropTypes.object,
};

export default function SearchHit({
  className,
  styles = defaultStyles,
  label: title,
  secondaryTitle,
  url,
  corner,
  educationInformation,
  educationOtherContent,
  ...restProps
}) {
  const { t } = useTranslation();

  const corners = useCornersInfo();
  const { complementaryTexts } = useEducationSettings(corner);

  let [showInfo, setShowInfo] = useState(false);

  const {
    orientation,
    municipality,
    duration,
    studyPace,
    courseStartDates,
    studyAddress,
    needsApproval,
    openForRegistration,
    canceled,
  } = educationInformation;

  const { application, informationMeeting, requirements } =
    educationOtherContent;

  const toggleInfo = useEducationInfo({
    studyAddress: studyAddress,
    requirements: useRequirements(requirements),
    application: application,
    informationMeeting: informationMeeting,
    approval: useApproval(needsApproval, complementaryTexts),
  });

  return (
    <Section>
      <SearchTeaser
        as="li"
        className={clsx(className, styles.teaser)}
        css={{
          "--search-hit-background": `var(--page-theme-${corners[corner].theme}-card-background)`,
          "--search-hit-title-color": `var(--page-theme-default-color)`,
        }}
      >
        <SearchTeaserContent>
          <div className={styles.content}>
            <div className={styles.info}>
              <SearchTeaserMeta styles={styles}>
                <span
                  className={styles.corner}
                  css={{
                    "--search-hit-meta-background": `var(--page-theme-${corners[corner].theme})`,
                    "--search-hit-meta-color": `var(--page-theme-inverse)`,
                  }}
                >
                  {corners[corner].name}
                </span>
                {!!orientation && <span>{orientation.name}</span>}
              </SearchTeaserMeta>
              <SearchTeaserTitle link={{ url }} styles={styles}>
                {title}
                {secondaryTitle && ` - ${secondaryTitle}`}
              </SearchTeaserTitle>
              <p>
                {[
                  duration + " " + t("weeksLabel"),
                  t(`${studyPace}PaceLabel`),
                  municipality?.name,
                ].join(", ")}
              </p>
            </div>
            <Occasions
              className={styles.occasions}
              dates={courseStartDates}
              cancelled={canceled}
              openForRegistration={openForRegistration}
            />
          </div>
          <div className={styles.toggleWrapper}>
            <Button
              as="button"
              className={styles.toggleButton}
              onClick={() => setShowInfo((showInfo = !showInfo))}
            >
              {showInfo ? t("hideInformationLabel") : t("moreInformationLabel")}
              <Icon name={showInfo ? "arrow-up" : "arrow-down"} />
            </Button>
            <div
              className={styles.toggleInfo}
              css={{
                display: showInfo ? "block" : "none",
              }}
            >
              <div>
                {toggleInfo.map((info, index) => {
                  return (
                    info && (
                      <div className={clsx(styles.infoItem)} key={index}>
                        <Section>
                          <H className={clsx(styles.infoItemTitle)}>
                            {info.title}
                          </H>
                          {info.data}
                        </Section>
                      </div>
                    )
                  );
                })}
              </div>
              <div className={styles.link}>
                <Link to={url} className={styles.readMore}>
                  {t("readMoreEducationLabel")}
                </Link>
              </div>
            </div>
          </div>
        </SearchTeaserContent>
      </SearchTeaser>
    </Section>
  );
}
